<template>
  <div>
    <b-overlay :show="isFetchingPromotions">
      <b-card>
        <b-row align-v="center">
          <b-col>
            <h6>
              {{ `${$t('fields.result')} (${total})` }}
            </h6>
          </b-col>
          <b-col
            cols="3"
            class="text-right"
          >
            <b-button 
              v-if="$allowPermission('agent:manage.promotion')"
              variant="relief-primary"
              to="/promotions/create"
            >
              <feather-icon
                icon="PlusIcon"
              />
              {{ $t('buttons.add') }}
            </b-button>
          </b-col>
        </b-row>
        
        <b-row
          align-v="center"
          class="my-2"
        >
          <b-col v-if="isOwner">
            <master-select-input 
              :value="selectedMaster"
              hide-label
              @update="onMasterIdChange"
            />
          </b-col>
          <b-col v-if="isOwner || isMaster">
            <agent-select-input
              :value="selectedAgent"
              :master-id="selectedMaster"
              hide-label
              @update="onAgentIdChange"
            />
          </b-col>
        </b-row>
        <b-table
          :items="filteredList"
          :fields="fields"
          responsive
          show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(orderPosition)="data">
            <b-form-select
              :value="data.value"
              :options="orderOption"
              @change="(value) => changePromotionOrder(data.item, value)"
            />
          </template>
          <template #cell(type)="data">
            {{ displayPromotionType(data.value) }}
          </template>
          <template #cell(bonusType)="data">
            <span v-if="data.value === 0"> เปอร์เซ็นต์ </span>
            <span v-else-if="data.value === 1"> ค่าคงที่ </span>
            <span v-else-if="data.value === 2"> สุ่ม </span>
            <span v-else>data.value</span>
          </template>
          <template #cell(forGameType)="data">
            <span v-if="data.value === 0"> ทุกเกม </span>
            <span v-else-if="data.value === 1"> สล็อต </span>
            <span v-else-if="data.value === 2"> ยิงปลา </span>
            <span v-else-if="data.value === 3"> คาสิโน </span>
            <span v-else>data.value</span>
          </template>
          <template #cell(turnType)="data">
            <span v-if="data.value === 0"> เทิร์นเครดิต </span>
            <span v-else-if="data.value === 1"> เทิร์นโอเวอร์ </span>
            <span v-else-if="data.value === 2"> ไม่มีเทิร์น </span>
            <span v-else>data.value</span>
          </template>
          <template #cell(isActive)="data">
            <b-badge :variant="data.value? 'success': 'danger'">
              {{ data.value? $t('utils.status.active'): $t('utils.status.inactive') }}
            </b-badge>
          </template>
          <template #cell(id)="data">
            <b-button-group
              v-if="$allowPermission('agent:manage.promotion')"
            >
              <button
                type="button"
                class="btn btn-info btn-sm"
                @click="$router.push(`/promotions/detail/${data.value}`)"
              >
                {{ $t('buttons.edit') }}
              </button>
              <button
                type="button"
                class="btn btn-danger btn-sm"
                style="width:60px"
                @click="onRemovePromotion(data.value)"
              >
                {{ $t('buttons.remove') }}
              </button>
            </b-button-group>
          </template>
          <template #empty="">
            <p class="text-center text-muted">
              {{
                $t('messages.nothing_here')
              }}
            </p>
          </template>
        </b-table>
        <b-row>
          <b-col md="4">
            <page-limit-select
              :value="limit"
              @update="onLimitChange"
            />
          </b-col>
          <b-col>
            <pagination-input
              :per-page="limit"
              :total="total"
              @update="(val) => currentPage = val"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'List of Promotion',
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      selectedMaster: '',
      selectedAgent: '',
      search: '',
      fields: [
        '#',
        {
          key: 'title',
          label: this.$t('promotion.name'),
          thStyle: {
            minWidth: '250px',
          },
        },
        {
          key: 'type',
          label: this.$t('fields.type'),
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'bonusType',
          label: this.$t('promotion.bonusType'),
          thStyle: {
            minWidth: '140px',
          },
        },
        {
          key: 'forGameType',
          label: 'ชนิดเกม',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'turnType',
          label: 'ประเภทเทิร์น',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'turnRate',
          label: this.$t('cashback.turnover_point'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'orderPosition',
          label: this.$t('promotion.order_position'),
          thStyle: {
            minWidth: '150px',
          },
          class: 'text-center',
        },
        {
          key: 'isActive',
          label: 'สถานะ',
          thStyle: {
            minWidth: '100px',
          },
          class: 'text-center',
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '130px',
          },
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingPromotions: (state) => state.promotion.isFetchingPromotions,
    }),
    ...mapGetters(['isOwner', 'isMaster', 'promotions', 'userInfo']),
    filteredList() {
      return this.promotions.items
    },
    orderOption() {
      return [...Array(this.filteredList?.length + 1).keys()].map((i) => i)
    },
    pagination() {
      return this.promotions.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPromotions', 'updatePromotion', 'updatePromotionOrder', 'deletePromotion']),
    fetchData() {
      this.fetchPromotions({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent,
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    displayPromotionType(type) {
      switch (type) {
        case 0:
          return 'ฟรีเครดิต'
        case 1:
          return 'ฝากครั้งแรก'
        case 2:
          return 'ฝากครั้งแรกของวัน'
        case 3:
          return 'ทุกยอดฝาก'
        case 4:
          return 'นาทีทอง'
        case 5:
          return 'กงล้อ'
        case 6:
          return 'เปิดไพ่'
        case 7:
          return 'เช็คอิน'
        case 8:
          return 'Welcome Back'
        default:
          return '-'
      }
    },
    onRemovePromotion(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deletePromotion(id)
          }
        })
    },
    changePromotionOrder(item, position) {
      if (item.orderPosition !== position) {
        this.updatePromotionOrder({
          id: item.id,
          data: { orderPosition: position },
        })
      }
    },
  },
}
</script>
